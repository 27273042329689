// src/App.jsx
import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar } from 'recharts';
import './styles/css/tailwind.css'; // Ensure Tailwind is imported
import defaultModelsData from './default-models.json';

// Default models data (Top-level constant)
const defaultModels = defaultModelsData;

// Document types with token multipliers (Top-level constant)
const documentTypes = [
    { id: 1, name: "Plain Text", tokenMultiplier: 0.25 },
    { id: 2, name: "Rich Text/Markdown", tokenMultiplier: 0.3 },
    { id: 3, name: "PDF", tokenMultiplier: 0.33 },
    { id: 4, name: "Word Document", tokenMultiplier: 0.35 },
    { id: 5, name: "Code/Technical", tokenMultiplier: 0.4 } // Highest multiplier
];

// *** Scenario Preset Definitions ***
const scenarios = {
    worst: { users: 250, queries: 25, docPerc: 80, docType: 5, docSize: 50000, pasteSize: 5000, ratio: 90 },
    medium: { users: 250, queries: 10, docPerc: 40, docType: 3, docSize: 10000, pasteSize: 2000, ratio: 75 },
    best: { users: 250, queries: 5, docPerc: 10, docType: 1, docSize: 2000, pasteSize: 500, ratio: 50 }
};

// --- Model storage utilities (Top-level functions) ---
const saveModels = (models) => {
    try {
        localStorage.setItem('azure_simulator_models', JSON.stringify(models));
        return true;
    } catch (error) { console.error('LocalStorage save error:', error); return false; }
};

const resetToDefaultModels = () => {
    try {
        const defaultsCopy = JSON.parse(JSON.stringify(defaultModels));
        localStorage.setItem('azure_simulator_models', JSON.stringify(defaultsCopy));
        return defaultsCopy;
    } catch (error) {
        console.error('LocalStorage reset error:', error);
        return JSON.parse(JSON.stringify(defaultModels));
    }
};

const exportModels = (models) => {
    try {
        const modelData = JSON.stringify(models, null, 2);
        const blob = new Blob([modelData], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'azure_openai_models.json';
        document.body.appendChild(link); link.click(); document.body.removeChild(link);
        URL.revokeObjectURL(url);
        return true;
    } catch (error) { console.error('Export error:', error); return false; }
};

const importModels = (file) => {
    return new Promise((resolve, reject) => {
        if (!file) return reject(new Error('No file provided.'));
        const reader = new FileReader();
        reader.onload = (event) => {
            try {
                const models = JSON.parse(event.target.result);
                if (!Array.isArray(models)) return reject(new Error('Imported data is not an array'));
                // More robust validation and default setting
                const validModels = models.filter(m => m && typeof m === 'object' && m.id && m.name && typeof m.inputPrice === 'number' && typeof m.outputPrice === 'number')
                    .map((m, index) => ({
                        id: m.id || (index + 1), // Assign ID if missing
                        name: String(m.name || `Model ${index + 1}`),
                        inputPrice: Math.max(0, Number(m.inputPrice) || 0),
                        outputPrice: Math.max(0, Number(m.outputPrice) || 0),
                        maxContextWindow: Math.max(1000, parseInt(m.maxContextWindow, 10) || 8192), // Ensure minimum context
                        provider: String(m.provider || 'Azure OpenAI')
                    }));

                if (validModels.length === 0) return reject(new Error('No valid models found in the imported file.'));

                // Re-index IDs to ensure uniqueness and sequential nature if needed (optional, but safer)
                // const reIndexedModels = validModels.map((m, index) => ({ ...m, id: index + 1 }));

                // Save valid models *before* resolving
                saveModels(validModels); // Using original validModels or reIndexedModels
                resolve(validModels);
            } catch (error) { reject(new Error(`Parse Error: ${error.message}`)); }
        };
        reader.onerror = () => reject(new Error('File read error.'));
        reader.readAsText(file);
    });
};


// --- Calculation Utilities (Top-level functions) ---

// Utility to calculate tokens based on characters and doc type multiplier
// Note: This is kept separate as it might be useful elsewhere.
const calculateTokens = (chars, typeId) => {
    const multiplier = documentTypes.find(t=>t.id===typeId)?.tokenMultiplier || 0.25; // Default to plain text multiplier
    return (Math.max(0, Number(chars)||0) * multiplier);
}

// REFACTORED calculateTokensPerDay to use multipliers directly for clarity
const calculateTokensPerDay = ({ activeUsers, avgQueriesPerUser, docUploadPercent, avgDocumentSize, selectedDocType, avgTextPasteSize }) => {
    const pastePerc = 100 - (Number(docUploadPercent) || 0);
    const uploadPerc = (Number(docUploadPercent) || 0);

    // Find the multipliers ONCE
    const docTypeMultiplier = documentTypes.find(t => t.id === (Number(selectedDocType) || 1))?.tokenMultiplier || 0.25;
    const plainTextMultiplier = documentTypes.find(t => t.id === 1)?.tokenMultiplier || 0.25; // Assuming pastes are plain text

    // Use the found multipliers directly
    const docChars = Math.max(0, Number(avgDocumentSize) || 0);
    const pasteChars = Math.max(0, Number(avgTextPasteSize) || 0);

    const docTokens = (uploadPerc / 100) * (docChars * docTypeMultiplier);
    const pasteTokens = (pastePerc / 100) * (pasteChars * plainTextMultiplier);

    const avgTokPerQuery = docTokens + pasteTokens;

    // Calculate total daily tokens
    return (Math.max(0, Number(activeUsers) || 0) * Math.max(0, Number(avgQueriesPerUser) || 0) * avgTokPerQuery);
};

const calculateAzureCost = (tokensPerDay, modelData, inputRatio) => {
    // Ensure inputRatio is between 0 and 1
    const ratio = Math.max(0, Math.min(1, Number(inputRatio)||0));
    const tokens = Math.max(0, Number(tokensPerDay)||0);
    // Ensure prices are non-negative numbers
    const inPrice = Math.max(0, Number(modelData?.inputPrice)||0);
    const outPrice = Math.max(0, Number(modelData?.outputPrice)||0);

    // Calculate cost per MILLION tokens
    const inputCost = (tokens * ratio / 1000000) * inPrice;
    const outputCost = (tokens * (1 - ratio) / 1000000) * outPrice;

    return inputCost + outputCost;
};

const generateSimulationData = ({ tokensPerDay, dailyAzureCost, monthlyHostingCost, simulationDays }) => {
    const data = [];
    let accTokens = 0;
    let accAzureCost = 0;
    // Ensure inputs are valid numbers
    const hosting = Math.max(0, Number(monthlyHostingCost)||0);
    const days = Math.max(1, Math.round(Number(simulationDays))||1); // Ensure at least 1 day
    const dailyTokens = Math.max(0, Number(tokensPerDay)||0);
    const dailyCost = Math.max(0, Number(dailyAzureCost)||0);

    for (let day = 1; day <= days; day++) {
        accTokens += dailyTokens;
        accAzureCost += dailyCost;
        // Calculate accumulated hosting cost proportionally
        const accHosting = (hosting * day / 30); // Simple linear daily proportion of monthly cost
        data.push({
            day,
            tokens: accTokens,
            azureCost: accAzureCost,
            totalCost: accAzureCost + accHosting
        });
    }
    return data;
};

const generateModelComparison = ({ tokensPerDay, models, monthlyHostingCost, inputRatio }) => {
    const hosting = Math.max(0, Number(monthlyHostingCost)||0);
    const ratio = Math.max(0, Math.min(1, Number(inputRatio)||0)); // Ensure ratio is valid

    return (models || []).map(m => {
        // Ensure model prices are valid before calculating
        const validModelData = {
            ...m,
            inputPrice: Math.max(0, Number(m.inputPrice)||0),
            outputPrice: Math.max(0, Number(m.outputPrice)||0)
        };
        const daily = calculateAzureCost(tokensPerDay, validModelData, ratio);
        const monthly = (daily * 30) + hosting; // Simple 30-day month approximation
        return { name: m.name, dailyCost: daily, monthlyCost: monthly };
    });
};

// --- Optimization Parameter Steps ---
// Define discrete steps for parameters to check during optimization.
// Adjust these steps for finer/coarser granularity vs. performance trade-off.
const optimizationSteps = {
    avgQueriesPerUser: [5, 10, 15, 25, 40], // Example steps
    docUploadPercent: [10, 25, 50, 75, 90], // Example steps
    inputRatioPercent: [50, 70, 80, 90, 95], // Example steps
    avgDocumentSize: [2000, 5000, 10000, 25000, 50000], // Example steps
    avgTextPasteSize: [500, 1500, 3000, 5000, 10000], // Example steps
    // Note: selectedDocType could also be optimized, but adds another loop dimension. Kept fixed for now.
};

// --- Optimization Calculation Utility ---
const findOptimalSettings = ({
                                 budgetTarget,
                                 budgetType, // 'total' or 'perUser'
                                 models,
                                 // Fixed parameters for the optimization run
                                 activeUsers,
                                 monthlyHostingCost,
                                 selectedDocType // Use the currently selected doc type for calculation simplicity
                             }) => {
    const results = {};
    const safeBudgetTarget = Number(budgetTarget) || 0;
    const safeActiveUsers = Number(activeUsers) || 0;
    const safeMonthlyHostingCost = Number(monthlyHostingCost) || 0;

    if (!models || models.length === 0 || safeBudgetTarget <= 0) {
        return results; // No models or invalid budget
    }

    // Calculate the absolute total monthly budget threshold
    const totalMonthlyBudget = budgetType === 'perUser'
        ? safeBudgetTarget * safeActiveUsers
        : safeBudgetTarget;

    // Check if budget is sufficient even for hosting cost
    if (totalMonthlyBudget <= safeMonthlyHostingCost && totalMonthlyBudget > 0) {
        console.warn("Budget target is less than or equal to monthly hosting cost. No Azure usage possible.");
        return results; // Budget too low even without Azure costs (unless budget is 0)
    }
    if (totalMonthlyBudget <= 0) {
        console.warn("Budget target is zero or negative.");
        return results; // Zero or negative budget
    }

    // Iterate through each model to find valid settings
    models.forEach(model => {
        const validCombinationsForModel = [];
        // Nested loops through discrete steps for each parameter
        for (const queries of optimizationSteps.avgQueriesPerUser) {
            for (const docPerc of optimizationSteps.docUploadPercent) {
                for (const ratioPerc of optimizationSteps.inputRatioPercent) {
                    for (const docSize of optimizationSteps.avgDocumentSize) {
                        for (const pasteSize of optimizationSteps.avgTextPasteSize) {
                            // Construct the parameter set for this iteration
                            const currentParams = {
                                activeUsers: safeActiveUsers,
                                avgQueriesPerUser: queries,
                                docUploadPercent: docPerc,
                                selectedDocType: selectedDocType, // Using the main selected type
                                avgDocumentSize: docSize,
                                avgTextPasteSize: pasteSize,
                            };

                            // Calculate costs for this specific combination
                            const tokensDay = calculateTokensPerDay(currentParams);
                            // Ensure model prices are valid for cost calculation
                            const validModelData = {
                                ...model,
                                inputPrice: Math.max(0, Number(model.inputPrice)||0),
                                outputPrice: Math.max(0, Number(model.outputPrice)||0)
                            };
                            const dailyAzure = calculateAzureCost(tokensDay, validModelData, ratioPerc / 100);
                            const monthlyTotal = (dailyAzure * 30) + safeMonthlyHostingCost; // 30-day month approx

                            // Check if calculated cost is within budget (and non-negative)
                            if (monthlyTotal >= 0 && monthlyTotal <= totalMonthlyBudget) {
                                // Store the valid combination of parameters
                                validCombinationsForModel.push({
                                    avgQueriesPerUser: queries,
                                    docUploadPercent: docPerc,
                                    inputRatioPercent: ratioPerc,
                                    avgDocumentSize: docSize,
                                    avgTextPasteSize: pasteSize,
                                    estimatedMonthlyCost: monthlyTotal,
                                    estimatedDailyTokens: tokensDay, // Store for potential display/info
                                    estimatedDailyAzureCost: dailyAzure // Store for potential display/info
                                });
                            }
                        } // End pasteSize loop
                    } // End docSize loop
                } // End ratioPerc loop
            } // End docPerc loop
        } // End queries loop

        // If valid combinations were found for this model, store them
        if (validCombinationsForModel.length > 0) {
            // Sort results by estimated cost (cheapest first)
            validCombinationsForModel.sort((a, b) => a.estimatedMonthlyCost - b.estimatedMonthlyCost);
            // Limit the number of results stored per model to avoid overwhelming the UI
            results[model.id] = validCombinationsForModel.slice(0, 50); // Store only top 50 cheapest
        }
    }); // End models.forEach

    return results; // Return the object containing results per model ID
};


// --- CSV Export Utility ---

// Helper to sanitize CSV cell content
const sanitizeCsvCell = (cellData) => {
    const stringData = String(cellData ?? ''); // Ensure it's a string, handle null/undefined
    // If data contains comma, newline, or double quote, enclose in double quotes
    if (stringData.includes(',') || stringData.includes('\n') || stringData.includes('"')) {
        // Escape existing double quotes by doubling them
        const escapedData = stringData.replace(/"/g, '""');
        return `"${escapedData}"`;
    }
    return stringData;
};

// Helper to sanitize filenames
const sanitizeFilename = (name) => {
    // Remove or replace characters invalid in filenames (Windows/Unix common)
    return name.replace(/[/\\?%*:|"<>]/g, '-'); // Replace common invalid chars with hyphen
}

// Function to generate CSV and trigger download for Optimization Results table
const exportOptimizationDataToCsv = (data, modelName) => {
    if (!data || data.length === 0) {
        alert("No data available to export for this model.");
        return;
    }

    // Define headers (matching the table columns)
    const headers = [
        'Avg Q/User',
        'Doc %',
        'Input %',
        'Doc Size (Chars)',
        'Paste Size (Chars)',
        'Est. Monthly $'
    ];

    // Map data object keys to headers for correct ordering in CSV
    const dataKeys = [
        'avgQueriesPerUser',
        'docUploadPercent',
        'inputRatioPercent',
        'avgDocumentSize',
        'avgTextPasteSize',
        'estimatedMonthlyCost'
    ];

    // Create header row, sanitizing each header
    const headerRow = headers.map(sanitizeCsvCell).join(',');

    // Create data rows by mapping data objects
    const dataRows = data.map(combo => {
        // Map values based on dataKeys order
        return dataKeys.map(key => {
            let value = combo[key];
            // Apply specific formatting for CSV if needed (optional)
            if (key === 'estimatedMonthlyCost') {
                // Export as number with 2 decimal places
                value = Number(value).toFixed(2);
            } else if (key === 'docUploadPercent' || key === 'inputRatioPercent') {
                // Export the raw number (without '%')
                value = combo[key]
            }
            // Sanitize each cell's value
            return sanitizeCsvCell(value);
        }).join(','); // Join cells with comma
    });

    // Combine header and data rows with newline characters
    // Add UTF-8 BOM (Byte Order Mark) for better Excel compatibility
    const csvContent = '\uFEFF' + [headerRow, ...dataRows].join('\n');

    // Create Blob and trigger download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    // Create a safe filename from the model name
    const safeModelName = sanitizeFilename(modelName);
    link.download = `optimization_results_${safeModelName}.csv`; // Set download filename
    document.body.appendChild(link); // Append link to body
    link.click(); // Simulate click to trigger download
    document.body.removeChild(link); // Remove link from body
    URL.revokeObjectURL(url); // Clean up the object URL
};


// --- Main App Component ---
function App() {
    // Ref for file input
    const fileInputRef = useRef(null);

    // --- State Variables ---
    // Model Management
    const [models, setModels] = useState([]);
    const [selectedModel, setSelectedModel] = useState(null); // Store model ID
    const [showModelManager, setShowModelManager] = useState(false);
    const [isAddingModel, setIsAddingModel] = useState(false);
    const [isEditingModel, setIsEditingModel] = useState(false);
    const [modelToEdit, setModelToEdit] = useState(null); // Store the whole model object being edited
    // Model Form State
    const [modelName, setModelName] = useState('');
    const [inputPrice, setInputPrice] = useState(0);
    const [outputPrice, setOutputPrice] = useState(0);
    const [maxContextWindow, setMaxContextWindow] = useState(8192);
    const [provider, setProvider] = useState('Azure OpenAI');
    // Simulator Parameters
    const [activeUsers, setActiveUsers] = useState(250);
    const [avgQueriesPerUser, setAvgQueriesPerUser] = useState(10);
    const [docUploadPercent, setDocUploadPercent] = useState(40);
    const [selectedDocType, setSelectedDocType] = useState(1); // Store doc type ID
    const [avgDocumentSize, setAvgDocumentSize] = useState(5000);
    const [avgTextPasteSize, setAvgTextPasteSize] = useState(1500);
    const [windowSizeTokens, setWindowSizeTokens] = useState(8000); // Informational/planning state
    const [monthlyHostingCost, setMonthlyHostingCost] = useState(500);
    const [inputRatioPercent, setInputRatioPercent] = useState(90);
    const [simulationDays, setSimulationDays] = useState(30);
    // Calculation Results State
    const [tokensPerDay, setTokensPerDay] = useState(0);
    const [dailyAzureCost, setDailyAzureCost] = useState(0);
    const [totalMonthlyCost, setTotalMonthlyCost] = useState(0);
    const [simulationData, setSimulationData] = useState([]);
    const [scenarioComparison, setScenarioComparison] = useState([]);
    // UI State
    const [isDarkMode, setIsDarkMode] = useState(() => {
        const savedMode = localStorage.getItem('darkMode');
        if (savedMode !== null) return savedMode === 'true';
        return window.matchMedia('(prefers-color-scheme: dark)').matches; // Default based on system pref
    });
    // Budget Optimization State
    const [budgetTarget, setBudgetTarget] = useState(1000); // Default budget
    const [budgetType, setBudgetType] = useState('total'); // 'total' or 'perUser'
    const [optimizationResults, setOptimizationResults] = useState(null); // null: not run, {}: run but no results, { modelId: [...] }: results found
    const [isOptimizing, setIsOptimizing] = useState(false); // Loading state for optimization
    // State for Optimization Results table visibility
    const [collapsedResults, setCollapsedResults] = useState({}); // { modelId: true/false, ... }


    // --- Chart Theming Variables ---
    // Memoize chart theme variables to prevent recalculation on every render unless isDarkMode changes
    const chartTheme = useMemo(() => ({
        axisLabelColor: isDarkMode ? '#D1D5DB' : '#374151', // gray-300 dark / gray-700 light
        tickColor: isDarkMode ? '#9CA3AF' : '#6B7280',      // gray-400 dark / gray-500 light
        gridColor: isDarkMode ? '#374151' : '#e5e7eb',      // gray-700 dark / gray-200 light
        tooltipBg: isDarkMode ? 'rgba(55, 65, 81, 0.9)' : 'rgba(255, 255, 255, 0.9)', // gray-700 dark / white light
        tooltipText: isDarkMode ? '#F3F4F6' : '#1F2937',     // gray-100 dark / gray-800 light
        legendColor: isDarkMode ? '#D1D5DB' : '#374151'      // gray-300 dark / gray-700 light
    }), [isDarkMode]);


    // --- Effects ---
    // Dark Mode Management Effect
    useEffect(() => {
        const root = document.documentElement;
        if (isDarkMode) {
            root.classList.add('dark');
            localStorage.setItem('darkMode', 'true');
        } else {
            root.classList.remove('dark');
            localStorage.setItem('darkMode', 'false');
        }
    }, [isDarkMode]);

    // Model Loading from LocalStorage (on initial mount) Effect
    useEffect(() => {
        try {
            const storedModelsJson = localStorage.getItem('azure_simulator_models');
            let loadedModels = storedModelsJson ? JSON.parse(storedModelsJson) : defaultModels;

            // Validate loaded models structure and provide defaults if necessary
            if (!Array.isArray(loadedModels) || loadedModels.length === 0) {
                console.warn("No valid models in localStorage or empty array, resetting to defaults.");
                loadedModels = resetToDefaultModels(); // Load and save defaults
            } else if (!storedModelsJson) {
                // If using defaults because nothing was stored, save them now
                saveModels(loadedModels);
            }

            // Ensure all models have necessary fields with defaults
            const validatedModels = loadedModels.map((m, index) => ({
                id: m.id || (index + 1), // Assign sequential ID if missing
                name: String(m.name || `Model ${index + 1}`),
                inputPrice: Math.max(0, Number(m.inputPrice) || 0),
                outputPrice: Math.max(0, Number(m.outputPrice) || 0),
                maxContextWindow: Math.max(1000, parseInt(m.maxContextWindow, 10) || 8192),
                provider: String(m.provider || 'Azure OpenAI')
            }));

            setModels(validatedModels);

            // Set selected model: Prefer currently selected if still valid, else first model, else null
            const currentSelectionValid = validatedModels.some(m => m.id === selectedModel);
            if (currentSelectionValid) {
                // Keep current selection if it exists in the newly loaded/validated list
            } else if (validatedModels.length > 0) {
                setSelectedModel(validatedModels[0].id); // Select the first model if available
            } else {
                setSelectedModel(null); // No models available
            }

        } catch (error) {
            console.error('Error loading models from localStorage:', error);
            // Fallback to defaults on any loading error
            const fallbackModels = resetToDefaultModels();
            setModels(fallbackModels);
            setSelectedModel(fallbackModels.length > 0 ? fallbackModels[0].id : null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Run only once on component mount


    // Save models to LocalStorage whenever the models state changes Effect
    useEffect(() => {
        // Avoid saving an empty array during initial load potentially before validation
        if (models.length > 0) {
            saveModels(models);
        }
    }, [models]);


    // Main Calculation Logic Effect (runs when parameters change)
    useEffect(() => {
        // If no models or no model is selected, reset calculations
        if (models.length === 0 || selectedModel === null) {
            setTokensPerDay(0);
            setDailyAzureCost(0);
            setTotalMonthlyCost(Number(monthlyHostingCost) || 0); // Still show hosting cost
            setSimulationData([]);
            setScenarioComparison([]);
            return; // Exit early
        }

        // Find the currently selected model data
        const currentModelData = models.find(m => m.id === selectedModel);
        // Handle case where selectedModel ID might be invalid (e.g., after deleting the selected model)
        if (!currentModelData) {
            console.error("Selected model data not found for ID:", selectedModel);
            // Optionally reset selection to the first available model or show error
            if (models.length > 0) setSelectedModel(models[0].id);
            else setSelectedModel(null);
            // Reset calculations as a safety measure
            setTokensPerDay(0);
            setDailyAzureCost(0);
            setTotalMonthlyCost(Number(monthlyHostingCost) || 0);
            setSimulationData([]);
            setScenarioComparison([]);
            return;
        }

        // Recalculate based on current simulator inputs
        const tpd = calculateTokensPerDay({ activeUsers, avgQueriesPerUser, docUploadPercent, avgDocumentSize, selectedDocType, avgTextPasteSize });
        setTokensPerDay(tpd);

        const inputRatio = (Number(inputRatioPercent) || 0) / 100;
        const dailyAzure = calculateAzureCost(tpd, currentModelData, inputRatio);
        setDailyAzureCost(dailyAzure);

        const monthlyTotal = (dailyAzure * 30) + (Number(monthlyHostingCost) || 0);
        setTotalMonthlyCost(monthlyTotal);

        // Generate data for charts based on new calculations
        setSimulationData(generateSimulationData({ tokensPerDay: tpd, dailyAzureCost: dailyAzure, monthlyHostingCost, simulationDays }));
        setScenarioComparison(generateModelComparison({ tokensPerDay: tpd, models, monthlyHostingCost, inputRatio }));

        // Dependencies that trigger recalculation
    }, [activeUsers, avgQueriesPerUser, docUploadPercent, selectedDocType, avgDocumentSize, avgTextPasteSize, monthlyHostingCost, simulationDays, selectedModel, models, inputRatioPercent]);


    // Effect to Adjust Informational Window Size Tokens based on selected model's max context
    useEffect(() => {
        const currentModelData = models.find(m => m.id === selectedModel);
        const maxWindow = currentModelData?.maxContextWindow || 8192; // Use model's max or default

        // Adjust the informational slider value if it exceeds the current model's max
        // Use functional update to prevent issues with stale state
        setWindowSizeTokens(prevWindowSize => Math.min(prevWindowSize, maxWindow));

        // If no model is selected, reset the informational slider to a default
        if (selectedModel === null) {
            setWindowSizeTokens(8000); // Or another suitable default
        }

    }, [models, selectedModel]); // Rerun only when models list or selected model ID changes


    // --- Handlers ---

    // Resets the model add/edit form fields
    const resetModelForm = useCallback(() => {
        setModelName('');
        setInputPrice(0);
        setOutputPrice(0);
        setMaxContextWindow(8192);
        setProvider('Azure OpenAI');
    }, []); // No dependencies needed

    // Opens the 'Add New Model' form
    const handleAddNewModelClick = useCallback(() => {
        resetModelForm(); // Clear form
        setIsAddingModel(true); // Set mode to adding
        setIsEditingModel(false);
        setModelToEdit(null); // Ensure no model is marked for editing
    }, [resetModelForm]);

    // Opens the 'Edit Model' form and populates it with existing model data
    const handleEditModelClick = useCallback((model) => {
        // Populate form fields with the data of the model being edited
        setModelName(model.name);
        setInputPrice(model.inputPrice);
        setOutputPrice(model.outputPrice);
        setMaxContextWindow(model.maxContextWindow || 8192); // Use default if undefined
        setProvider(model.provider || 'Azure OpenAI'); // Use default if undefined
        setModelToEdit(model); // Store the model object being edited
        setIsEditingModel(true); // Set mode to editing
        setIsAddingModel(false);
    }, []); // No dependencies needed, uses argument `model`

    // Saves a new model to the state
    const handleSaveNewModel = useCallback(() => {
        const trimmedName = modelName.trim();
        if (!trimmedName) return alert("Model name is required.");

        // Validate numeric inputs
        const ip = parseFloat(inputPrice);
        const op = parseFloat(outputPrice);
        const mc = parseInt(maxContextWindow, 10);

        if (isNaN(ip) || isNaN(op) || isNaN(mc) || ip < 0 || op < 0 || mc < 1000) {
            return alert("Valid non-negative prices and a maximum context of at least 1000 tokens are required.");
        }

        // Create the new model object
        const newModel = {
            // Generate a unique ID (simple approach: max existing ID + 1)
            id: models.length > 0 ? Math.max(...models.map(m => m.id)) + 1 : 1,
            name: trimmedName,
            inputPrice: ip,
            outputPrice: op,
            maxContextWindow: mc,
            provider: provider.trim() || 'Azure OpenAI' // Default provider if empty
        };

        // Update models state by adding the new model
        setModels(prevModels => [...prevModels, newModel]);
        setIsAddingModel(false); // Close form after saving
        resetModelForm();       // Clear form fields
    }, [modelName, inputPrice, outputPrice, maxContextWindow, provider, models, resetModelForm]); // Dependencies

    // Updates an existing model in the state
    const handleUpdateExistingModel = useCallback(() => {
        const trimmedName = modelName.trim();
        if (!trimmedName || !modelToEdit) return alert("Model name is required and a model must be selected for editing.");

        // Validate numeric inputs
        const ip = parseFloat(inputPrice);
        const op = parseFloat(outputPrice);
        const mc = parseInt(maxContextWindow, 10);

        if (isNaN(ip) || isNaN(op) || isNaN(mc) || ip < 0 || op < 0 || mc < 1000) {
            return alert("Valid non-negative prices and a maximum context of at least 1000 tokens are required.");
        }

        // Update models state by mapping and replacing the edited model
        setModels(prevModels =>
            prevModels.map(m =>
                m.id === modelToEdit.id // Find the model by ID
                    ? { // Create updated model object
                        ...m, // Keep original ID and any other properties not being edited
                        name: trimmedName,
                        inputPrice: ip,
                        outputPrice: op,
                        maxContextWindow: mc,
                        provider: provider.trim() || 'Azure OpenAI'
                    }
                    : m // Return unchanged model if ID doesn't match
            )
        );

        setIsEditingModel(false); // Close form after updating
        setModelToEdit(null);    // Clear editing state
        resetModelForm();        // Clear form fields
    }, [modelName, inputPrice, outputPrice, maxContextWindow, provider, modelToEdit, resetModelForm]); // Dependencies

    // Cancels the add/edit operation and closes the form
    const handleCancelEditAdd = useCallback(() => {
        setIsAddingModel(false);
        setIsEditingModel(false);
        setModelToEdit(null);
        resetModelForm();
    }, [resetModelForm]); // Dependency

    // Deletes a model from the state
    const handleDeleteModelClick = useCallback((idToDelete) => {
        // Prevent deleting the last model
        if (models.length <= 1) {
            return alert('Cannot delete the last remaining model.');
        }
        // Confirm deletion with user
        const modelNameToDelete = models.find(m => m.id === idToDelete)?.name || `ID ${idToDelete}`;
        if (!window.confirm(`Are you sure you want to delete the model "${modelNameToDelete}"?`)) {
            return; // User cancelled
        }

        // Filter out the model to be deleted
        const updatedModels = models.filter(m => m.id !== idToDelete);
        setModels(updatedModels);

        // Adjust selectedModel if the deleted model was the one selected
        if (selectedModel === idToDelete) {
            setSelectedModel(updatedModels.length > 0 ? updatedModels[0].id : null); // Select first or null
        }

        // Close the edit form if the model being edited was the one deleted
        if (modelToEdit?.id === idToDelete) {
            handleCancelEditAdd();
        }

    }, [models, selectedModel, modelToEdit, handleCancelEditAdd]); // Dependencies

    // Resets models to default and reloads the page
    const handleResetModelsClick = useCallback(() => {
        if (window.confirm('Are you sure you want to reset all models to the default list? This will reload the page and discard any current parameter settings.')) {
            resetToDefaultModels(); // Update localStorage first
            window.location.reload(); // Force reload to clear all state
        }
    }, []); // No dependencies

    // Exports the current models list as a JSON file
    const handleExportModelsClick = useCallback(() => {
        if (!exportModels(models)) { // Call utility function
            alert("Failed to export models. See console for details.");
        }
    }, [models]); // Dependency

    // Triggers the hidden file input for importing models
    const handleImportTriggerClick = useCallback(() => {
        fileInputRef.current?.click();
    }, []); // No dependencies

    // Handles the file selection for importing models
    const handleImportFileSelected = useCallback(async (event) => {
        const file = event.target.files?.[0];
        if (!file) return; // No file selected

        try {
            // Call utility function (which also saves to localStorage)
            const imported = await importModels(file);
            setModels(imported); // Update state with imported models
            // Select the first imported model or null if none valid
            setSelectedModel(imported.length > 0 ? imported[0].id : null);
            alert(`Successfully imported ${imported.length} models.`);
            handleCancelEditAdd(); // Close add/edit form if it was open
        } catch (err) {
            console.error("Import Error:", err);
            alert(`Import Error: ${err.message}`); // Show error to user
        } finally {
            // Reset file input value so the same file can be selected again if needed
            if (event.target) {
                event.target.value = '';
            }
        }
    }, [handleCancelEditAdd]); // Include handleCancelEditAdd dependency


    // Helper to get the current max context window for the selected model (used for slider limit)
    const getCurrentMaxWindow = useCallback(() => {
        const currentModelData = models.find(m => m.id === selectedModel);
        return currentModelData?.maxContextWindow || 8192; // Default if model not found or no context set
    }, [models, selectedModel]); // Dependencies


    // Applies preset scenario values to the simulator parameters
    const applyScenario = useCallback((scenarioName) => {
        const scenario = scenarios[scenarioName];
        if (!scenario) {
            console.error("Invalid scenario name:", scenarioName);
            return;
        }
        // Apply scenario values to relevant state variables
        setActiveUsers(scenario.users);
        setAvgQueriesPerUser(scenario.queries);
        setDocUploadPercent(scenario.docPerc);
        // Ensure the document type from the scenario exists, fallback to 1 (Plain Text) if not
        const docTypeExists = documentTypes.some(dt => dt.id === scenario.docType);
        setSelectedDocType(docTypeExists ? scenario.docType : 1);
        setAvgDocumentSize(scenario.docSize);
        setAvgTextPasteSize(scenario.pasteSize);
        setInputRatioPercent(scenario.ratio);
        // Optionally reset simulation days or other non-scenario parameters?
        // setSimulationDays(30);
    }, []); // Dependencies: scenarios, documentTypes (constants, safe to omit)


    // --- Handler for Budget Optimization Calculation ---
    const handleOptimizeClick = useCallback(() => {
        setIsOptimizing(true); // Set loading state
        setOptimizationResults(null); // Clear previous results
        setCollapsedResults({}); // Reset collapsed state for tables

        // Use a timeout to allow the UI to update (show loading) before potentially blocking calculation
        setTimeout(() => {
            try {
                // Prepare fixed parameters for the optimization function
                const fixedParams = {
                    activeUsers: Number(activeUsers) || 0,
                    monthlyHostingCost: Number(monthlyHostingCost) || 0,
                    selectedDocType: Number(selectedDocType) || 1 // Use current selection
                };
                // Call the optimization logic
                const results = findOptimalSettings({
                    budgetTarget: Number(budgetTarget) || 0,
                    budgetType,
                    models,
                    ...fixedParams
                });
                setOptimizationResults(results); // Store the results
            } catch (error) {
                console.error("Optimization Error:", error);
                alert(`An error occurred during optimization: ${error.message}`);
                setOptimizationResults({}); // Indicate error/no results found by setting empty object
            } finally {
                setIsOptimizing(false); // Ensure loading state is turned off
            }
        }, 50); // Small delay (e.g., 50ms)

    }, [budgetTarget, budgetType, models, activeUsers, monthlyHostingCost, selectedDocType]); // Dependencies


    // --- Handler to toggle individual table visibility in Optimization Results ---
    const toggleTableVisibility = useCallback((modelId) => {
        setCollapsedResults(prevState => ({
            ...prevState, // Keep existing states
            [modelId]: !prevState[modelId] // Toggle the boolean value for the specific modelId
        }));
    }, []); // No dependencies needed

    // --- Handler to toggle ALL tables in Optimization Results ---
    const handleToggleAllTables = useCallback(() => {
        // Only proceed if results exist
        if (!optimizationResults || Object.keys(optimizationResults).length === 0) return;

        // Get IDs of models that actually have results displayed
        const modelIdsWithResults = Object.keys(optimizationResults);

        // Determine if currently all displayed tables are collapsed
        const areAllCollapsed = modelIdsWithResults.every(id => collapsedResults[id]);

        // If all are currently collapsed, show all; otherwise, collapse all.
        const nextCollapsedState = {};
        if (areAllCollapsed) {
            // Set all to false (visible) - achieved by setting state to empty object
        } else {
            // Set all to true (collapsed)
            modelIdsWithResults.forEach(id => {
                nextCollapsedState[id] = true;
            });
        }
        // Update the state
        setCollapsedResults(nextCollapsedState);

    }, [optimizationResults, collapsedResults]); // Dependencies


    // --- Render ---
    return (
        // Main container with background and text colors based on dark mode
        <div className="min-h-screen bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-gray-200 transition-colors duration-200">
            {/* Centered content container */}
            <div className="p-4 md:p-6 max-w-7xl mx-auto">
                {/* Page Title */}
                <h1 className="text-3xl font-bold mb-6 text-center md:text-left text-gray-800 dark:text-white">DRAX GPT Azure OpenAI Cost Simulator</h1>

                {/* Action Bar (Model Management Toggle, Dark Mode, Import/Export, Reset) */}
                <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow mb-6">
                    <div className="flex flex-wrap gap-3 justify-between items-center">
                        {/* Toggle Model Manager Button */}
                        <button
                            onClick={() => setShowModelManager(!showModelManager)}
                            className={`px-4 py-2 rounded-md font-medium transition-colors ${
                                showModelManager
                                    ? 'bg-gray-300 dark:bg-gray-600 text-gray-800 dark:text-gray-100 hover:bg-gray-400 dark:hover:bg-gray-500'
                                    : 'bg-blue-500 dark:bg-blue-600 text-white hover:bg-blue-600 dark:hover:bg-blue-700'
                            }`}
                        >
                            {showModelManager ? 'Hide Model Manager' : 'Manage Models'}
                        </button>
                        {/* Right-side Action Buttons */}
                        <div className="flex flex-wrap gap-3 items-center">
                            {/* Dark Mode Toggle */}
                            <button
                                onClick={() => setIsDarkMode(!isDarkMode)}
                                className="px-3 py-2 rounded-md bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors"
                                title={isDarkMode ? "Switch to Light Mode" : "Switch to Dark Mode"}
                            >
                                {isDarkMode ? '☀️' : '🌙'}
                            </button>
                            {/* Reset Defaults Button */}
                            <button
                                onClick={handleResetModelsClick}
                                title="Reset models to default and reload page"
                                className="bg-yellow-500 text-white px-4 py-2 rounded-md hover:bg-yellow-600 transition-colors"
                            >
                                Reset Defaults
                            </button>
                            {/* Export Models Button */}
                            <button
                                onClick={handleExportModelsClick}
                                title="Download current models as JSON"
                                className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition-colors"
                                disabled={models.length === 0} // Disable if no models to export
                            >
                                Export Models
                            </button>
                            {/* Import Models Button */}
                            <button
                                onClick={handleImportTriggerClick}
                                title="Import models from JSON file"
                                className="bg-purple-500 text-white px-4 py-2 rounded-md hover:bg-purple-600 transition-colors"
                            >
                                Import Models
                            </button>
                            {/* Hidden file input used by the Import button */}
                            <input
                                type="file"
                                ref={fileInputRef} // Ref for triggering click
                                onChange={handleImportFileSelected} // Handler for file selection
                                accept=".json,application/json" // Specify accepted file types
                                className="hidden" // Keep input visually hidden
                            />
                        </div>
                    </div>
                </div>

                {/* Scenario Buttons Section */}
                <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow mb-6">
                    <h2 className="text-lg font-semibold mb-3 text-gray-800 dark:text-white">Apply Scenario Presets</h2>
                    <div className="flex flex-wrap gap-3">
                        <button onClick={() => applyScenario('worst')} className="px-4 py-2 rounded-md bg-red-600 hover:bg-red-700 text-white transition-colors text-sm font-medium" title="Applies high usage/cost parameters"> Worst Case </button>
                        <button onClick={() => applyScenario('medium')} className="px-4 py-2 rounded-md bg-yellow-500 hover:bg-yellow-600 text-white transition-colors text-sm font-medium" title="Applies moderate usage/cost parameters"> Medium Case </button>
                        <button onClick={() => applyScenario('best')} className="px-4 py-2 rounded-md bg-green-600 hover:bg-green-700 text-white transition-colors text-sm font-medium" title="Applies low usage/cost parameters"> Best Case </button>
                    </div>
                    <p className="text-xs text-gray-500 dark:text-gray-400 mt-2">Note: Applies example preset values to the Usage, Input, and Cost parameters below.</p>
                </div>

                {/* Model Manager Panel (Conditionally Rendered based on showModelManager state) */}
                {showModelManager && (
                    <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow mb-6">
                        {/* Model Manager Header and Add Button */}
                        <div className="flex flex-wrap justify-between items-center mb-4 gap-4">
                            <h2 className="text-xl font-semibold text-gray-800 dark:text-white">Models Configuration</h2>
                            {/* Show Add button only if not currently adding or editing */}
                            {!(isAddingModel || isEditingModel) && (
                                <button
                                    onClick={handleAddNewModelClick}
                                    className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 dark:hover:bg-green-700 transition-colors"
                                >
                                    Add New Model
                                </button>
                            )}
                        </div>

                        {/* Add/Edit Model Form (Conditionally Rendered based on isAdding/isEditing state) */}
                        {(isAddingModel || isEditingModel) && (
                            <div className="mb-6 p-4 border rounded-md bg-gray-50 dark:bg-gray-700 dark:border-gray-600">
                                <h3 className="font-medium text-lg mb-3 text-gray-800 dark:text-white">
                                    {/* Dynamic form title */}
                                    {isAddingModel ? 'Add New Model' : `Edit Model: ${modelToEdit?.name || ''}`}
                                </h3>
                                {/* Form Grid */}
                                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                                    {/* Model Name Input */}
                                    <div>
                                        <label className="block text-sm font-medium mb-1 dark:text-gray-300">Model Name <span className="text-red-500">*</span></label>
                                        <input type="text" value={modelName} onChange={(e) => setModelName(e.target.value)} className="w-full p-2 border rounded-md dark:bg-gray-600 dark:border-gray-500 dark:text-white dark:placeholder-gray-400" required />
                                    </div>
                                    {/* Provider Input */}
                                    <div>
                                        <label className="block text-sm font-medium mb-1 dark:text-gray-300">Provider</label>
                                        <input type="text" value={provider} onChange={(e) => setProvider(e.target.value)} placeholder="e.g., Azure OpenAI" className="w-full p-2 border rounded-md dark:bg-gray-600 dark:border-gray-500 dark:text-white dark:placeholder-gray-400"/>
                                    </div>
                                    {/* Input Price Input */}
                                    <div>
                                        <label className="block text-sm font-medium mb-1 dark:text-gray-300">Input Price ($/1M tk) <span className="text-red-500">*</span></label>
                                        <input type="number" value={inputPrice} onChange={(e) => setInputPrice(e.target.value)} className="w-full p-2 border rounded-md dark:bg-gray-600 dark:border-gray-500 dark:text-white" min="0" step="any" required />
                                    </div>
                                    {/* Output Price Input */}
                                    <div>
                                        <label className="block text-sm font-medium mb-1 dark:text-gray-300">Output Price ($/1M tk) <span className="text-red-500">*</span></label>
                                        <input type="number" value={outputPrice} onChange={(e) => setOutputPrice(e.target.value)} className="w-full p-2 border rounded-md dark:bg-gray-600 dark:border-gray-500 dark:text-white" min="0" step="any" required />
                                    </div>
                                    {/* Max Context Window Input */}
                                    <div>
                                        <label className="block text-sm font-medium mb-1 dark:text-gray-300">Max Context (tokens) <span className="text-red-500">*</span></label>
                                        <input type="number" value={maxContextWindow} onChange={(e) => setMaxContextWindow(e.target.value)} className="w-full p-2 border rounded-md dark:bg-gray-600 dark:border-gray-500 dark:text-white" min="1000" step="1" required />
                                    </div>
                                </div>
                                {/* Form Action Buttons (Save/Update, Cancel) */}
                                <div className="flex gap-3 mt-4">
                                    <button
                                        onClick={isAddingModel ? handleSaveNewModel : handleUpdateExistingModel}
                                        className={`${
                                            isAddingModel
                                                ? 'bg-green-500 hover:bg-green-600 dark:hover:bg-green-700'
                                                : 'bg-blue-500 hover:bg-blue-600 dark:hover:bg-blue-700'
                                        } text-white px-4 py-2 rounded-md transition-colors`}
                                    >
                                        {isAddingModel ? 'Save New Model' : 'Update Model'}
                                    </button>
                                    <button
                                        onClick={handleCancelEditAdd}
                                        className="bg-gray-300 text-gray-800 px-4 py-2 rounded-md hover:bg-gray-400 dark:bg-gray-500 dark:text-gray-100 dark:hover:bg-gray-400 transition-colors"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        )}

                        {/* Models Table Display */}
                        <div className="overflow-x-auto">
                            <table className="w-full border-collapse text-sm dark:border-gray-700">
                                {/* Table Header */}
                                <thead>
                                <tr className="bg-gray-100 dark:bg-gray-700">
                                    <th className="p-2 text-left border font-medium dark:border-gray-600 dark:text-gray-200">Name</th>
                                    <th className="p-2 text-left border font-medium dark:border-gray-600 dark:text-gray-200">Provider</th>
                                    <th className="p-2 text-left border font-medium dark:border-gray-600 dark:text-gray-200">Input ($/1M)</th>
                                    <th className="p-2 text-left border font-medium dark:border-gray-600 dark:text-gray-200">Output ($/1M)</th>
                                    <th className="p-2 text-left border font-medium dark:border-gray-600 dark:text-gray-200">Context</th>
                                    <th className="p-2 text-left border font-medium dark:border-gray-600 dark:text-gray-200">Actions</th>
                                </tr>
                                </thead>
                                {/* Table Body - Mapping over models state */}
                                <tbody>
                                {models.map(m => (
                                    <tr
                                        key={m.id} // Unique key for each row
                                        // Highlight row if it's the currently selected model
                                        className={`border-b dark:border-gray-700 ${
                                            m.id === selectedModel ? 'bg-blue-50 dark:bg-blue-900/20' : 'hover:bg-gray-50 dark:hover:bg-gray-700/50'
                                        }`}
                                    >
                                        <td className="p-2 border dark:border-gray-600 font-medium dark:text-gray-100">{m.name}</td>
                                        <td className="p-2 border dark:border-gray-600 dark:text-gray-300">{m.provider}</td>
                                        <td className="p-2 border dark:border-gray-600 dark:text-gray-300">${Number(m.inputPrice).toFixed(2)}</td>
                                        <td className="p-2 border dark:border-gray-600 dark:text-gray-300">${Number(m.outputPrice).toFixed(2)}</td>
                                        <td className="p-2 border dark:border-gray-600 dark:text-gray-300">{Number(m.maxContextWindow).toLocaleString()} tk</td>
                                        {/* Action Buttons Cell */}
                                        <td className="p-2 border dark:border-gray-600">
                                            <div className="flex gap-2 flex-wrap">
                                                {/* Edit Button */}
                                                <button
                                                    onClick={() => handleEditModelClick(m)}
                                                    className="bg-blue-500 text-white px-2 py-1 rounded text-xs hover:bg-blue-600 dark:bg-blue-600 dark:hover:bg-blue-700 transition-colors"
                                                    // Disable if add/edit form is already open
                                                    disabled={isAddingModel || isEditingModel}
                                                >
                                                    Edit
                                                </button>
                                                {/* Delete Button */}
                                                <button
                                                    onClick={() => handleDeleteModelClick(m.id)}
                                                    className="bg-red-500 text-white px-2 py-1 rounded text-xs hover:bg-red-600 dark:bg-red-600 dark:hover:bg-red-700 transition-colors"
                                                    // Disable if form open or if it's the last model
                                                    disabled={isAddingModel || isEditingModel || models.length <= 1}
                                                >
                                                    Delete
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                            {/* Message shown if no models are configured */}
                            {models.length === 0 && (
                                <p className="text-center text-gray-500 dark:text-gray-400 py-4">No models configured. Add a model or import/reset to defaults.</p>
                            )}
                        </div>
                    </div>
                )} {/* End Model Manager Panel */}

                {/* Budget Optimization Input Section */}
                <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow mb-6">
                    <h2 className="text-lg font-semibold mb-3 text-gray-800 dark:text-white">Budget Optimization</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 items-end">
                        {/* Budget Target Input */}
                        <div>
                            <label htmlFor="budgetTarget" className="block text-sm font-medium mb-1 dark:text-gray-300">Target Budget ($)</label>
                            <input
                                type="number"
                                id="budgetTarget"
                                value={budgetTarget}
                                onChange={e => setBudgetTarget(Math.max(0, Number(e.target.value)))}
                                className="w-full p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                min="0"
                                step="100"
                                disabled={isOptimizing} // Disable while calculating
                            />
                        </div>
                        {/* Budget Type Selection (Radio Buttons) */}
                        <div>
                            <label className="block text-sm font-medium mb-1 dark:text-gray-300">Budget Type</label>
                            <div className="flex gap-4 items-center mt-1">
                                {/* Total Monthly Radio */}
                                <label className="flex items-center gap-1 cursor-pointer">
                                    <input
                                        type="radio"
                                        name="budgetType"
                                        value="total"
                                        checked={budgetType === 'total'}
                                        onChange={() => setBudgetType('total')}
                                        className="dark:accent-blue-500"
                                        disabled={isOptimizing} // Disable while calculating
                                    /> Total Monthly
                                </label>
                                {/* Per User Monthly Radio */}
                                <label className="flex items-center gap-1 cursor-pointer">
                                    <input
                                        type="radio"
                                        name="budgetType"
                                        value="perUser"
                                        checked={budgetType === 'perUser'}
                                        onChange={() => setBudgetType('perUser')}
                                        className="dark:accent-blue-500"
                                        disabled={isOptimizing} // Disable while calculating
                                    /> Per User Monthly
                                </label>
                            </div>
                            {/* Display Calculated Total Budget */}
                            <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">
                                Calculated Total Monthly Budget: <strong>${(budgetType === 'perUser' ? (Number(budgetTarget) || 0) * (Number(activeUsers) || 0) : (Number(budgetTarget) || 0)).toLocaleString()}</strong>
                                {budgetType === 'perUser' && ` (using ${activeUsers.toLocaleString()} users from Usage section)`}
                            </p>
                        </div>
                        {/* Optimize Button */}
                        <div>
                            <button
                                onClick={handleOptimizeClick}
                                className={`w-full px-4 py-2 rounded-md font-medium transition-colors ${isOptimizing ? 'bg-gray-400 cursor-not-allowed' : 'bg-indigo-600 hover:bg-indigo-700 text-white'}`}
                                disabled={isOptimizing || models.length === 0} // Disable if optimizing or no models
                            >
                                {isOptimizing ? 'Optimizing...' : 'Find Optimal Settings'}
                            </button>
                            {/* Message if no models exist */}
                            {models.length === 0 && <p className="text-xs text-red-500 dark:text-red-400 mt-1 text-center">Add models first.</p>}
                        </div>
                    </div>
                    {/* Explanatory Note */}
                    <p className="text-xs text-gray-500 dark:text-gray-400 mt-2">
                        Note: Optimization finds settings within the calculated budget. It uses the <strong>current 'Active Users/Day'</strong> value from the Usage section (it does not change it), along with current Hosting Cost and selected Avg Doc Type.
                    </p>
                </div> {/* End Budget Optimization Input Section */}

                {/* Budget Optimization Results Section */}
                {/* Show loading indicator while optimizing */}
                {isOptimizing && (
                    <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow mb-6 text-center">
                        <p className="font-semibold animate-pulse dark:text-gray-300">Calculating optimal settings...</p>
                    </div>
                )}
                {/* Render results only after optimization is complete (optimizationResults is not null) */}
                {optimizationResults !== null && !isOptimizing && (
                    <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow mb-6">
                        {/* Results Section Header with Master Toggle Button */}
                        <div className="flex flex-wrap justify-between items-center mb-4 gap-2 border-b pb-2 dark:border-gray-700">
                            <h2 className="text-lg font-semibold text-gray-800 dark:text-white">
                                Optimization Results (Within Budget: ${(budgetType === 'perUser' ? (Number(budgetTarget) || 0) * (Number(activeUsers) || 0) : (Number(budgetTarget) || 0)).toLocaleString()} / month)
                            </h2>
                            {/* Master Show/Hide All Tables Button */}
                            {/* Show button only if there are results */}
                            {Object.keys(optimizationResults).length > 0 && (
                                <button
                                    onClick={handleToggleAllTables}
                                    className="px-3 py-1 rounded-md bg-blue-600 hover:bg-blue-700 text-white transition-colors text-xs font-medium"
                                    title="Show/Hide all result tables"
                                >
                                    {/* Determine button text based on current state */}
                                    {Object.keys(optimizationResults).every(id => collapsedResults[id]) ? 'Show All Tables' : 'Hide All Tables'}
                                </button>
                            )}
                        </div>

                        {/* Message if no results found for any model */}
                        {Object.keys(optimizationResults).length === 0 ? (
                            <p className="text-center text-gray-600 dark:text-gray-400">No parameter combinations found within the specified budget for any model using the defined optimization steps.</p>
                        ) : (
                            // Container for results per model
                            <div className="space-y-6">
                                {/* Iterate through models that HAVE results */}
                                {models.filter(m => optimizationResults[m.id] && optimizationResults[m.id].length > 0).map(model => {
                                    // Check if the current table is collapsed
                                    const isCollapsed = collapsedResults[model.id];
                                    return (
                                        // Container for a single model's results
                                        <div key={model.id}>
                                            {/* Header Row with Model Title and Action Buttons */}
                                            <div className="flex flex-wrap justify-between items-center mb-2 gap-2">
                                                <h3 className="text-md font-semibold text-blue-600 dark:text-blue-400">
                                                    Model: {model.name}
                                                </h3>
                                                {/* Button Group (Toggle, Export) */}
                                                <div className="flex gap-2">
                                                    {/* Individual Show/Hide Table Toggle Button */}
                                                    <button
                                                        onClick={() => toggleTableVisibility(model.id)}
                                                        className="px-3 py-1 rounded-md bg-gray-500 hover:bg-gray-600 text-white transition-colors text-xs font-medium"
                                                        title={isCollapsed ? `Show results table for ${model.name}` : `Hide results table for ${model.name}`}
                                                    >
                                                        {isCollapsed ? 'Show Table' : 'Hide Table'}
                                                    </button>
                                                    {/* Export CSV Button */}
                                                    <button
                                                        onClick={() => exportOptimizationDataToCsv(optimizationResults[model.id], model.name)}
                                                        className="px-3 py-1 rounded-md bg-teal-600 hover:bg-teal-700 text-white transition-colors text-xs font-medium"
                                                        title={`Export results for ${model.name} to CSV`}
                                                    >
                                                        Export CSV
                                                    </button>
                                                </div>
                                            </div> {/* End Header Row */}

                                            {/* Conditionally Render the Results Table based on collapsed state */}
                                            {!isCollapsed && (
                                                <div className="overflow-x-auto">
                                                    {/* Table structure */}
                                                    <table className="w-full border-collapse text-xs dark:border-gray-700">
                                                        {/* Table Head */}
                                                        <thead className="bg-gray-100 dark:bg-gray-700">
                                                        <tr>
                                                            <th className="p-2 text-left border font-medium dark:border-gray-600 dark:text-gray-200">Avg Q/User</th>
                                                            <th className="p-2 text-left border font-medium dark:border-gray-600 dark:text-gray-200">Doc %</th>
                                                            <th className="p-2 text-left border font-medium dark:border-gray-600 dark:text-gray-200">Input %</th>
                                                            <th className="p-2 text-left border font-medium dark:border-gray-600 dark:text-gray-200">Doc Size (Chars)</th>
                                                            <th className="p-2 text-left border font-medium dark:border-gray-600 dark:text-gray-200">Paste Size (Chars)</th>
                                                            <th className="p-2 text-left border font-medium dark:border-gray-600 dark:text-gray-200">Est. Monthly $</th>
                                                        </tr>
                                                        </thead>
                                                        {/* Table Body */}
                                                        <tbody className="dark:text-gray-300">
                                                        {/* Map over results for this specific model */}
                                                        {optimizationResults[model.id].map((combo, index) => (
                                                            <tr key={index} className="border-b dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700/50">
                                                                <td className="p-2 border dark:border-gray-600">{combo.avgQueriesPerUser}</td>
                                                                <td className="p-2 border dark:border-gray-600">{combo.docUploadPercent}%</td>
                                                                <td className="p-2 border dark:border-gray-600">{combo.inputRatioPercent}%</td>
                                                                <td className="p-2 border dark:border-gray-600">{combo.avgDocumentSize.toLocaleString()}</td>
                                                                <td className="p-2 border dark:border-gray-600">{combo.avgTextPasteSize.toLocaleString()}</td>
                                                                <td className="p-2 border dark:border-gray-600 font-medium">${combo.estimatedMonthlyCost.toFixed(2)}</td>
                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                    </table>
                                                    {/* Note if results were limited to top 50 */}
                                                    {optimizationResults[model.id].length === 50 && <p className="text-xs text-center mt-2 text-gray-500 dark:text-gray-400">(Showing top 50 cheapest results for {model.name})</p>}
                                                </div>
                                            )} {/* End Conditional Render for Table */}
                                        </div> // End Container for single model's results
                                    )
                                })} {/* End map over models with results */}

                                {/* Section listing models for which NO results were found */}
                                {models.filter(m => !optimizationResults[m.id] || optimizationResults[m.id].length === 0).length > 0 && (
                                    <div className="mt-4 border-t pt-4 dark:border-gray-700">
                                        <p className="text-sm font-medium text-gray-600 dark:text-gray-400">No results found within budget for:</p>
                                        <ul className="list-disc list-inside text-xs text-gray-500 dark:text-gray-500">
                                            {models.filter(m => !optimizationResults[m.id] || optimizationResults[m.id].length === 0).map(m => <li key={m.id}>{m.name}</li>)}
                                        </ul>
                                    </div>
                                )}
                            </div> // End Container for results per model
                        )}
                    </div> // End Optimization Results Section Container
                )} {/* End Conditional Render for Results Section */}


                {/* Main Simulator Layout (Parameters on Left, Results on Right) */}
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-6">
                    {/* Left Column: Input Parameters */}
                    <div className="lg:col-span-1 space-y-6">
                        {/* Usage Card */}
                        <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
                            <h2 className="text-lg font-semibold mb-4 border-b pb-2 dark:border-gray-700 dark:text-white">Usage</h2>
                            {/* Active Users Input */}
                            <div className="mb-3">
                                <label className="block text-sm font-medium mb-1 dark:text-gray-300">Active Users/Day</label>
                                <input type="number" value={activeUsers} onChange={e=>setActiveUsers(Math.max(0,Number(e.target.value)))} className="w-full p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white" min="0"/>
                            </div>
                            {/* Avg Queries Per User Slider */}
                            <div className="mb-3">
                                <label className="block text-sm font-medium mb-1 dark:text-gray-300" htmlFor="avgQueriesSlider">Avg Queries/User/Day: {avgQueriesPerUser}</label>
                                <input id="avgQueriesSlider" type="range" value={avgQueriesPerUser} onChange={e=>setAvgQueriesPerUser(Number(e.target.value))} className="w-full h-2 bg-gray-200 dark:bg-gray-600 rounded-lg appearance-none cursor-pointer" min="1" max="100"/>
                            </div>
                            {/* Document Upload Percentage Slider */}
                            <div className="mb-3">
                                <label className="block text-sm font-medium mb-1 dark:text-gray-300" htmlFor="docUploadSlider">Doc Upload %: {docUploadPercent}%</label>
                                <input id="docUploadSlider" type="range" value={docUploadPercent} onChange={e=>setDocUploadPercent(Number(e.target.value))} className="w-full h-2 bg-gray-200 dark:bg-gray-600 rounded-lg appearance-none cursor-pointer" min="0" max="100"/>
                                {/* Display corresponding Paste Percentage */}
                                <div className="text-xs text-gray-500 dark:text-gray-400 text-right">Paste: {100-docUploadPercent}%</div>
                            </div>
                        </div> {/* End Usage Card */}

                        {/* Cost Card */}
                        <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
                            <h2 className="text-lg font-semibold mb-4 border-b pb-2 dark:border-gray-700 dark:text-white">Cost</h2>
                            {/* Monthly Hosting Cost Input */}
                            <div className="mb-3">
                                <label className="block text-sm font-medium mb-1 dark:text-gray-300">Monthly Hosting ($)</label>
                                <input type="number" value={monthlyHostingCost} onChange={e=>setMonthlyHostingCost(Math.max(0,Number(e.target.value)))} className="w-full p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white" min="0" step="10"/>
                            </div>
                            {/* Input Token Ratio Slider */}
                            <div className="mb-3">
                                <label className="block text-sm font-medium mb-1 dark:text-gray-300" htmlFor="inputRatioSlider">Input Token Ratio: {inputRatioPercent}%</label>
                                <input id="inputRatioSlider" type="range" value={inputRatioPercent} onChange={e=>setInputRatioPercent(Number(e.target.value))} className="w-full h-2 bg-gray-200 dark:bg-gray-600 rounded-lg appearance-none cursor-pointer" min="0" max="100" step="1"/>
                                <div className="text-xs text-gray-500 dark:text-gray-400 flex justify-between">
                                    <span>Assumed % input tokens.</span>
                                    {/* Display corresponding Output Percentage */}
                                    <span className="text-right">Output: {100-inputRatioPercent}%</span>
                                </div>
                            </div>
                            {/* Simulation Duration Input */}
                            <div className="mb-3">
                                <label className="block text-sm font-medium mb-1 dark:text-gray-300">Sim Duration (Days)</label>
                                <input type="number" value={simulationDays} onChange={e=>setSimulationDays(Math.max(1,Number(e.target.value)))} className="w-full p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white" min="1" max="365"/>
                            </div>
                        </div> {/* End Cost Card */}

                        {/* Input Card */}
                        <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
                            <h2 className="text-lg font-semibold mb-4 border-b pb-2 dark:border-gray-700 dark:text-white">Input</h2>
                            {/* Average Uploaded Document Type Selection */}
                            <div className="mb-3">
                                <label className="block text-sm font-medium mb-1 dark:text-gray-300">Avg Upload Doc Type</label>
                                <select value={selectedDocType} onChange={e=>setSelectedDocType(Number(e.target.value))} className="w-full p-2 border rounded-md bg-white dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                                    {/* Populate options from documentTypes array */}
                                    {documentTypes.map(t=>(<option key={t.id} value={t.id}>{t.name} (x{t.tokenMultiplier})</option>))}
                                </select>
                            </div>
                            {/* Average Document Size Slider */}
                            <div className="mb-3">
                                <label className="block text-sm font-medium mb-1 dark:text-gray-300" htmlFor="docSizeSlider">Avg Doc Size (chars): {Number(avgDocumentSize).toLocaleString()}</label>
                                <input id="docSizeSlider" type="range" value={avgDocumentSize} onChange={e=>setAvgDocumentSize(Number(e.target.value))} className="w-full h-2 bg-gray-200 dark:bg-gray-600 rounded-lg appearance-none cursor-pointer" min="1000" max="100000" step="1000"/>
                                {/* Display estimated tokens based on selected doc type */}
                                <div className="text-xs text-gray-500 dark:text-gray-400">Est Tokens: ~{Math.round(calculateTokens(avgDocumentSize,selectedDocType)).toLocaleString()} (using selected type)</div>
                            </div>
                            {/* Average Paste Size Slider */}
                            <div className="mb-3">
                                <label className="block text-sm font-medium mb-1 dark:text-gray-300" htmlFor="pasteSizeSlider">Avg Paste Size (chars): {Number(avgTextPasteSize).toLocaleString()}</label>
                                <input id="pasteSizeSlider" type="range" value={avgTextPasteSize} onChange={e=>setAvgTextPasteSize(Number(e.target.value))} className="w-full h-2 bg-gray-200 dark:bg-gray-600 rounded-lg appearance-none cursor-pointer" min="100" max="25000" step="100"/>
                                {/* Display estimated tokens assuming plain text */}
                                <div className="text-xs text-gray-500 dark:text-gray-400">Est Tokens: ~{Math.round(calculateTokens(avgTextPasteSize,1)).toLocaleString()} (as plain text)</div>
                            </div>
                            {/* Target Window Size Slider (Informational) */}
                            <div className="mb-3">
                                <label className="block text-sm font-medium mb-1 dark:text-gray-300" htmlFor="windowSizeSlider">Target Window (tokens): {Number(windowSizeTokens).toLocaleString()}</label>
                                <input
                                    id="windowSizeSlider"
                                    type="range"
                                    value={windowSizeTokens}
                                    onChange={e=>setWindowSizeTokens(Number(e.target.value))}
                                    className="w-full h-2 bg-gray-200 dark:bg-gray-600 rounded-lg appearance-none cursor-pointer"
                                    min="1000"
                                    max={getCurrentMaxWindow()} // Dynamically set max based on selected model
                                    step="1000"
                                    disabled={!selectedModel} // Disable if no model selected
                                />
                                {/* Display info about target window and selected model's max */}
                                <div className="text-xs text-gray-500 dark:text-gray-400">
                                    <p>Informational: Approx. target context usage.</p>
                                    <p>Max for {models.find(m=>m.id===selectedModel)?.name||'N/A'}: {getCurrentMaxWindow().toLocaleString()} tk</p>
                                </div>
                            </div>
                        </div> {/* End Input Card */}
                    </div> {/* End Left Column */}

                    {/* Right Column: Results Display */}
                    <div className="lg:col-span-2 space-y-6">
                        {/* Top Row: Model Select + Summary */}
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                            {/* Model Selection Card */}
                            <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow md:col-span-1">
                                <h2 className="text-lg font-semibold mb-4 border-b pb-2 dark:border-gray-700 dark:text-white">Model</h2>
                                {/* Dropdown to select the model for calculations */}
                                <div className="mb-3">
                                    <label className="block text-sm font-medium mb-1 dark:text-gray-300">Select Model</label>
                                    <select
                                        value={selectedModel||''} // Use empty string for placeholder if nothing selected
                                        onChange={e=>setSelectedModel(e.target.value ? Number(e.target.value) : null)}
                                        className="w-full p-2 border rounded-md bg-white dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                        disabled={models.length===0} // Disable if no models
                                    >
                                        <option value="" disabled={selectedModel !== null}>-- Select a Model --</option>
                                        {/* Populate options from models state */}
                                        {models.map(m=>(
                                            <option key={m.id} value={m.id}>{m.name}</option>
                                        ))}
                                    </select>
                                </div>
                                {/* Display details of the currently selected model */}
                                {selectedModel && models.find(m=>m.id===selectedModel) && (
                                    <div className="text-xs space-y-1 mt-3 bg-blue-50 dark:bg-gray-700/50 p-2 rounded-md border border-blue-200 dark:border-gray-600 dark:text-gray-300">
                                        <h3 className="font-medium mb-1 dark:text-gray-100">{models.find(m=>m.id===selectedModel).name} Details:</h3>
                                        <div className="flex justify-between"><span>Provider:</span><span>{models.find(m=>m.id===selectedModel).provider}</span></div>
                                        <div className="flex justify-between"><span>Input $:</span><span>${models.find(m=>m.id===selectedModel).inputPrice.toFixed(2)}/1M</span></div>
                                        <div className="flex justify-between"><span>Output $:</span><span>${models.find(m=>m.id===selectedModel).outputPrice.toFixed(2)}/1M</span></div>
                                        <div className="flex justify-between"><span>Context:</span><span>{models.find(m=>m.id===selectedModel).maxContextWindow.toLocaleString()} tk</span></div>
                                    </div>
                                )}
                                {/* Message if no models are available */}
                                {models.length===0 && <p className="text-xs text-red-600 dark:text-red-400 mt-2">No models available. Please add or import models.</p>}
                            </div> {/* End Model Selection Card */}

                            {/* Summary Card (Tokens/Day, Daily Cost, Monthly Cost) */}
                            <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow md:col-span-2">
                                <h2 className="text-lg font-semibold mb-4 border-b pb-2 dark:border-gray-700 dark:text-white">Summary</h2>
                                <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-4 text-center">
                                    {/* Estimated Tokens/Day Display */}
                                    <div className="p-3 bg-blue-50 dark:bg-gray-700/30 rounded-md border border-blue-200 dark:border-gray-600">
                                        <div className="text-xl font-bold dark:text-blue-300">{tokensPerDay.toLocaleString(undefined,{maximumFractionDigits:0})}</div>
                                        <div className="text-sm text-gray-600 dark:text-gray-400">Est. Tokens/Day</div>
                                    </div>
                                    {/* Estimated Daily Azure Cost Display */}
                                    <div className="p-3 bg-green-50 dark:bg-gray-700/30 rounded-md border border-green-200 dark:border-gray-600">
                                        <div className="text-xl font-bold dark:text-green-300">${dailyAzureCost.toFixed(2)}</div>
                                        <div className="text-sm text-gray-600 dark:text-gray-400">Est. Daily Azure Cost</div>
                                    </div>
                                    {/* Estimated Monthly Total Cost Display */}
                                    <div className="p-3 bg-purple-50 dark:bg-gray-700/30 rounded-md border border-purple-200 dark:border-gray-600">
                                        <div className="text-xl font-bold dark:text-purple-300">${totalMonthlyCost.toFixed(2)}</div>
                                        <div className="text-sm text-gray-600 dark:text-gray-400">Est. Monthly Total Cost</div>
                                    </div>
                                </div>
                                {/* Note about monthly cost calculation */}
                                <p className="text-xs text-gray-500 dark:text-gray-400 text-center">Monthly Total ≈ (Daily Azure Cost * 30) + Monthly Hosting Cost</p>
                            </div> {/* End Summary Card */}
                        </div> {/* End Top Row */}

                        {/* Projection Charts Card */}
                        <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
                            <h2 className="text-lg font-semibold mb-4 border-b pb-2 dark:border-gray-700 dark:text-white">Cost & Token Projection ({simulationDays} Days)</h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-4">
                                {/* Accumulated Tokens Chart */}
                                <div>
                                    <h3 className="font-medium mb-2 text-center text-sm dark:text-gray-300">Accumulated Tokens</h3>
                                    <ResponsiveContainer width="100%" height={200}>
                                        {/* Line Chart for Tokens */}
                                        <LineChart data={simulationData} margin={{ top: 5, right: 5, left: 30, bottom: 20 }}>
                                            <CartesianGrid strokeDasharray="3 3" stroke={chartTheme.gridColor} />
                                            <XAxis dataKey="day" label={{ value: 'Days', position: 'insideBottom', offset: -10, fill: chartTheme.axisLabelColor }} tick={{ fill: chartTheme.tickColor }} />
                                            <YAxis
                                                label={{ value: 'Tokens', angle: -90, position: 'insideLeft', offset: -30, fill: chartTheme.axisLabelColor }}
                                                tick={{ fill: chartTheme.tickColor }}
                                                // Formatter for large numbers (K, M, B)
                                                tickFormatter={(value) => value >= 1e9 ? `${(value/1e9).toFixed(1)}B` : value >= 1e6 ? `${(value/1e6).toFixed(1)}M` : value >= 1e3 ? `${(value/1e3).toFixed(0)}K` : value}
                                                width={70} // Increased width for labels like '100.0M'
                                                domain={['auto', 'auto']} // Let Recharts determine Y-axis scale
                                            />
                                            <Tooltip
                                                contentStyle={{backgroundColor: chartTheme.tooltipBg, color: chartTheme.tooltipText, border:`1px solid ${chartTheme.gridColor}`, borderRadius:'4px'}}
                                                formatter={(value) => Math.round(value).toLocaleString()} // Format tooltip value
                                            />
                                            <Line type="monotone" dataKey="tokens" name="Tokens" stroke="#0077b6" strokeWidth={2} dot={false} />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </div>
                                {/* Accumulated Cost Chart */}
                                <div>
                                    <h3 className="font-medium mb-2 text-center text-sm dark:text-gray-300">Accumulated Cost</h3>
                                    <ResponsiveContainer width="100%" height={200}>
                                        {/* Line Chart for Costs */}
                                        <LineChart data={simulationData} margin={{ top: 5, right: 5, left: 30, bottom: 20 }}>
                                            <CartesianGrid strokeDasharray="3 3" stroke={chartTheme.gridColor} />
                                            <XAxis dataKey="day" label={{ value: 'Days', position: 'insideBottom', offset: -10, fill: chartTheme.axisLabelColor }} tick={{ fill: chartTheme.tickColor }}/>
                                            <YAxis
                                                label={{ value: 'Cost ($)', angle: -90, position: 'insideLeft', offset: -25, fill: chartTheme.axisLabelColor }}
                                                tick={{ fill: chartTheme.tickColor }}
                                                tickFormatter={(value) => `$${Math.round(value).toLocaleString()}`} // Format Y-axis ticks as currency
                                                width={70} // Ensure enough width for currency labels
                                                domain={['auto', 'auto']} // Auto-determine Y-axis scale
                                            />
                                            <Tooltip
                                                contentStyle={{backgroundColor: chartTheme.tooltipBg, color: chartTheme.tooltipText, border:`1px solid ${chartTheme.gridColor}`, borderRadius:'4px'}}
                                                formatter={(value) => `$${Number(value).toFixed(2)}`} // Format tooltip values as precise currency
                                            />
                                            <Legend wrapperStyle={{color: chartTheme.legendColor, paddingTop:'10px'}}/>
                                            {/* Lines for Azure Cost and Total Cost */}
                                            <Line type="monotone" dataKey="azureCost" name="Azure Cost" stroke="#0077b6" strokeWidth={2} dot={false}/>
                                            <Line type="monotone" dataKey="totalCost" name="Total (Inc. Hosting)" stroke="#9c27b0" strokeWidth={2} dot={false}/>
                                        </LineChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        </div> {/* End Projection Charts Card */}

                        {/* Model Comparison Chart Card */}
                        <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
                            <h2 className="text-lg font-semibold mb-4 border-b pb-2 dark:border-gray-700 dark:text-white">Model Comparison (Based on Current Settings)</h2>
                            <ResponsiveContainer width="100%" height={300}>
                                {/* Bar Chart for comparing models */}
                                {/* Increased bottom margin to prevent legend overlap */}
                                <BarChart data={scenarioComparison} margin={{ top: 5, right: 5, left: 25, bottom: 85 }}>
                                    <CartesianGrid strokeDasharray="3 3" stroke={chartTheme.gridColor}/>
                                    {/* X-axis with angled model names */}
                                    <XAxis dataKey="name" interval={0} angle={-45} textAnchor="end" height={70} tick={{fontSize: 10, fill: chartTheme.tickColor}}/>
                                    <YAxis
                                        label={{ value: 'Est. Cost ($)', angle: -90, position: 'insideLeft', offset: -15, fill: chartTheme.axisLabelColor }}
                                        tick={{ fill: chartTheme.tickColor }}
                                        tickFormatter={(value)=>`$${value.toLocaleString()}`} // Format Y-axis ticks as currency
                                        width={70} // Ensure space for labels
                                    />
                                    <Tooltip
                                        contentStyle={{backgroundColor: chartTheme.tooltipBg, color: chartTheme.tooltipText, border:`1px solid ${chartTheme.gridColor}`, borderRadius:'4px'}}
                                        formatter={(value) => `$${Number(value).toFixed(2)}`} // Format tooltip values as currency
                                    />
                                    {/* Legend - rely on increased margin for positioning */}
                                    <Legend wrapperStyle={{ color: chartTheme.legendColor }} />
                                    {/* Bars for Daily and Monthly costs */}
                                    <Bar dataKey="dailyCost" name="Est. Daily Azure" fill="#0077b6" />
                                    <Bar dataKey="monthlyCost" name="Est. Monthly Total" fill="#9c27b0" />
                                </BarChart>
                            </ResponsiveContainer>
                            {/* Message if no models to compare */}
                            {models.length === 0 && <p className="text-center text-gray-500 dark:text-gray-400 py-4">No models configured for comparison.</p>}
                        </div> {/* End Model Comparison Chart Card */}
                    </div> {/* End Right Column */}
                </div> {/* End Main Simulator Layout Grid */}

                {/* Parameter Summary Table Section */}
                <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow mt-6">
                    <h2 className="text-lg font-semibold mb-4 border-b pb-2 dark:border-gray-700 dark:text-white">Current Parameter Summary</h2>
                    <div className="overflow-x-auto">
                        {/* Table summarizing all current parameter values */}
                        <table className="w-full border-collapse text-sm dark:border-gray-700">
                            <thead className="bg-gray-100 dark:bg-gray-700">
                            <tr>
                                <th className="p-2 text-left border font-medium dark:border-gray-600 dark:text-gray-200">Parameter</th>
                                <th className="p-2 text-left border font-medium dark:border-gray-600 dark:text-gray-200">Value</th>
                                <th className="p-2 text-left border font-medium dark:border-gray-600 dark:text-gray-200">Description</th>
                            </tr>
                            </thead>
                            <tbody className="dark:text-gray-300">
                            {/* Rows for each parameter */}
                            <tr className="border-b dark:border-gray-700">
                                <td className="p-2 border dark:border-gray-600">Selected Model</td>
                                <td className="p-2 border dark:border-gray-600">{models.find(m=>m.id===selectedModel)?.name||'N/A'}</td>
                                <td className="p-2 border dark:border-gray-600">Model used for cost projection and comparison base.</td>
                            </tr>
                            <tr className="border-b dark:border-gray-700">
                                <td className="p-2 border dark:border-gray-600">Input/Output Ratio</td>
                                <td className="p-2 border dark:border-gray-600">{inputRatioPercent}% / {100-inputRatioPercent}%</td>
                                <td className="p-2 border dark:border-gray-600">Assumed ratio of input vs. output tokens processed.</td>
                            </tr>
                            <tr className="border-b dark:border-gray-700">
                                <td className="p-2 border dark:border-gray-600">Active Users</td>
                                <td className="p-2 border dark:border-gray-600">{activeUsers}/day</td>
                                <td className="p-2 border dark:border-gray-600">Number of users interacting with the system daily.</td>
                            </tr>
                            <tr className="border-b dark:border-gray-700">
                                <td className="p-2 border dark:border-gray-600">Avg Queries/User</td>
                                <td className="p-2 border dark:border-gray-600">{avgQueriesPerUser}/day</td>
                                <td className="p-2 border dark:border-gray-600">Average number of queries each active user makes per day.</td>
                            </tr>
                            <tr className="border-b dark:border-gray-700">
                                <td className="p-2 border dark:border-gray-600">Input Method Ratio</td>
                                <td className="p-2 border dark:border-gray-600">{docUploadPercent}% Doc Upload / {100-docUploadPercent}% Text Paste</td>
                                <td className="p-2 border dark:border-gray-600">Distribution of query input methods.</td>
                            </tr>
                            <tr className="border-b dark:border-gray-700">
                                <td className="p-2 border dark:border-gray-600">Avg Doc Type (Uploads)</td>
                                <td className="p-2 border dark:border-gray-600">{documentTypes.find(t=>t.id===selectedDocType)?.name || 'N/A'} (x{documentTypes.find(t=>t.id===selectedDocType)?.tokenMultiplier || 'N/A'})</td>
                                <td className="p-2 border dark:border-gray-600">Assumed average type for uploaded documents, affecting token count.</td>
                            </tr>
                            <tr className="border-b dark:border-gray-700">
                                <td className="p-2 border dark:border-gray-600">Avg Doc Size (Uploads)</td>
                                {/* Display estimated tokens using calculateTokens helper */}
                                <td className="p-2 border dark:border-gray-600">{Number(avgDocumentSize).toLocaleString()} chars (~{Math.round(calculateTokens(avgDocumentSize,selectedDocType)).toLocaleString()} tk)</td>
                                <td className="p-2 border dark:border-gray-600">Average size (in characters) of uploaded documents.</td>
                            </tr>
                            <tr className="border-b dark:border-gray-700">
                                <td className="p-2 border dark:border-gray-600">Avg Paste Size</td>
                                {/* Display estimated tokens using calculateTokens helper (assuming plain text ID: 1) */}
                                <td className="p-2 border dark:border-gray-600">{Number(avgTextPasteSize).toLocaleString()} chars (~{Math.round(calculateTokens(avgTextPasteSize,1)).toLocaleString()} tk)</td>
                                <td className="p-2 border dark:border-gray-600">Average size (in characters) of pasted text inputs. Assumed plain text.</td>
                            </tr>
                            <tr className="border-b dark:border-gray-700">
                                <td className="p-2 border dark:border-gray-600">Monthly Hosting Cost</td>
                                <td className="p-2 border dark:border-gray-600">${Number(monthlyHostingCost).toFixed(2)}</td>
                                <td className="p-2 border dark:border-gray-600">Fixed monthly infrastructure/hosting cost.</td>
                            </tr>
                            <tr className="border-b dark:border-gray-700">
                                <td className="p-2 border dark:border-gray-600">Simulation Duration</td>
                                <td className="p-2 border dark:border-gray-600">{simulationDays} days</td>
                                <td className="p-2 border dark:border-gray-600">Duration used for the projection charts.</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div> {/* End Parameter Summary Table Section */}

            </div> {/* End max-w container */}
        </div> // End main div
    );
}

export default App; // Export the component